import { FC, ReactNode } from "react";
import Image from "next/image";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const PageContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return (
      <div className="bg-white">
          {/* Header */}
          <header className="absolute inset-x-0 top-0 z-50">
              <nav
                  className="flex items-center justify-between p-6 lg:px-8"
                  aria-label="Global"
              >
                  <div className="flex lg:flex-1">
                      <a href="#" className="-m-1.5 p-1.5">
                          <span className="sr-only">
                              Maya Solutions UG
                          </span>

                          <Image
                              src={"/images/logo.png"}
                              className="h-8 w-auto"
                              alt={"Logo Maya Solutions"}
                              width={113}
                              height={80}
                          />
                      </a>
                  </div>
                  {/*
                  <div className="flex lg:hidden">
                      <button
                          type="button"
                          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                      >
                          <span className="sr-only">Open main menu</span>
                          <svg
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                          >
                              <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                              />
                          </svg>
                      </button>
                  </div>
                  <div className="hidden lg:flex lg:gap-x-12">
                      <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                          Product
                      </a>
                      <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                          Features
                      </a>
                      <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                          Marketplace
                      </a>
                      <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                          Company
                      </a>
                  </div>
                  <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                      <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                          Log in <span aria-hidden="true">→</span>
                      </a>
                  </div>

                  */}
              </nav>
              {/* Mobile menu, show/hide based on menu open state.
              <div className="lg:hidden" role="dialog" aria-modal="true">
                  <div className="fixed inset-0 z-50"/>
                  <div
                      className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                      <div className="flex items-center justify-between">
                          <a href="#" className="-m-1.5 p-1.5">
                              <span className="sr-only">Your Company</span>
                              <img
                                  className="h-8 w-auto"
                                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                  alt=""
                              />
                          </a>
                          <button
                              type="button"
                              className="-m-2.5 rounded-md p-2.5 text-gray-700"
                          >
                              <span className="sr-only">Close menu</span>
                              <svg
                                  className="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  aria-hidden="true"
                              >
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                  />
                              </svg>
                          </button>
                      </div>
                      <div className="mt-6 flow-root">
                          <div className="-my-6 divide-y divide-gray-500/10">
                              <div className="space-y-2 py-6">
                                  <a
                                      href="#"
                                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                  >
                                      Product
                                  </a>
                                  <a
                                      href="#"
                                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                  >
                                      Features
                                  </a>
                                  <a
                                      href="#"
                                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                  >
                                      Marketplace
                                  </a>
                                  <a
                                      href="#"
                                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                  >
                                      Company
                                  </a>
                              </div>
                              <div className="py-6">
                                  <a
                                      href="#"
                                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                  >
                                      Log in
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              */}
          </header>
          <main>
                {children}
          </main>
          {/* Footer */}
          <footer
              className="mt-32 bg-gray-900 sm:mt-56"
              aria-labelledby="footer-heading"
          >
              <h2 id="footer-heading" className="sr-only">
                  Footer
              </h2>
              <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-0">

                  <div className="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
                      <div className="flex space-x-6 md:order-2">

                          <a rel={"noreferrer"} target={"_blank"} href="https://www.linkedin.com/in/max-mayerhofer-a44078112/"
                             className="text-gray-500 hover:text-gray-400">
                              <span className="sr-only">LinkedIn</span>
                              <FontAwesomeIcon icon={faLinkedin} />
                          </a>
                      </div>
                      <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
                          <a href={"/imprint"} className="font-semibold text-gray-400 hover:text-gray-300">
                              Impressum
                          </a>
                          <span className="mx-4">•</span>
                          <a href={"/privacy-policy"} className="font-semibold text-gray-400 hover:text-gray-300">
                              Datenschutzerklärung
                          </a>
                      </p>
                  </div>
              </div>
          </footer>
      </div>


  );
};
